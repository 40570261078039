/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Payments by The Universe
 * Universe App Modules
 * 
 * Justin K Kazmierczak
 * 
 * Build's a module's individual components based on it's definition.
 * 
 **/

var errModule = require("./errors.js");

var config = false;
try {
    config = require("./config.js");
} catch (error) {
    //config is disabled for client side modules.
}
// var config = require("./config.js");


/**
 * Builds a module based on it's definition.
 * @param {*} exp The module.exports object.
 * @param {*} define The definition object.
 * @returns The module.exports object (can also be used as a _ object).
 */
function Create(exp, define) {

    if (!("namespace" in define)) {
        _.errors.noNamespace.throw(define);
    }

    exp.define = define;

    if ("errors" in define) {
        exp.errors = errModule.createFromDefine(define);
    }

    // //get property count of exp.errors
    // var errorCount = Object.keys(exp.errors).length;

    
    if ("config" in define) {

        if (config) {
            config.useDefine(define.config);
            exp.config = config;
        } else {
            console.warn("Access to config is denied for this module.")
        }

        // config.useDefine(define);
    }
    
    return exp;
} module.exports.create = Create;

_ = Create(module.exports, {
    namespace: "uam",
    title: "Universe App Modules",
    description: "The Universe App Modules is a module that allows you to create modules for The Universe. It makes common things like providing error messages, and defining configurations easy and effieceint.",
    errors: {
        "noNamespace": {
            title: "No Namespace",
            description: "The provided definiton object has no namespace.."
        }
    }
});

module.exports = Create;