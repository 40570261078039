/*!
 * Designed to help the app self report errors.
 */

var namespace = "uam.errors";
var registry = require("./registry.js");
var errorz = registry("errors");


var generateStacktrace = require("./functions/generateStacktrace.js").function;
var parseStackTrace = require("./functions/parseStackTrace.js").function;
var generateSafeError = require("./functions/generateSafeError.js").function;

// var jckConsole = require("@jumpcutking/console");

var unInitilized = new Error("The Error is not registered with the registry.");
unInitilized.namespace = namespace + ".unInitilized";
unInitilized.description = "An error element should register itself with the registry to help promote transparency across the app. It helps developers diagnose issues and helps users report issues.";

var propertyExists = new Error("A property with the same name already exists.");
propertyExists.namespace = namespace + ".propertyExists";
propertyExists.description = propertyExists.message;

/**
 * Adds an error to the registry.
 * @param {*} namespace The namespace used for identification.
 * @param {*} title The title or message of the error.
 * @param {*} description A more detailed description of the error.
 * @returns The error.
 */
function AddError(namespace, title, description) {
    var err = new Error(title);

    //namespace = namespace to lower and trim
    namespace = namespace.toLowerCase().trim();
    err.title = title;
    err.namespace = namespace;
    err.description = description;
    err.message = `${title}: ${description} (${namespace})`;

    try {
        errorz.add(err);
    } catch (error) {
        console.warn(`Error adding ${namespace} to registry. ${error.message}`);
    }

    return err;
} module.exports.add = AddError;

/**
 * Returns the error registry.
 * @returns {*} The error registry.
 */
function GetRegistry() {
    return errorz; 
} module.exports.getRegistry = GetRegistry;

/**
 * Adds an array of errors to the registry.
 * @param {*} errors An array of errors to add.
 */
function AddErrors(errors) {
    for (var i = 0; i < errors.length; i++) {
        AddError(errors[i].namespace, errors[i].title, errors[i].description);
    }
} module.exports.addErrors = AddErrors;

/**
 * Searches the registry for an error with the given namespace.
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @returns The error if found.
 */
function error(namespace, data = null) {

    // console.info(`Searching for error with namespace ${namespace}`, errors.registry);

    var e = errorz.search(namespace);

    //if data is not null
    if (data) {
        //add it to the error
        //Let's clone the error so we don't modify the original
        var nErr = new Error(`${e.message}: ${e.description}`);
        nErr.namespace = e.namespace;
        nErr.title = e.title;
        nErr.description = e.description;
        nErr.data = data;
        e = nErr; 
    }

    if (e) {
        return e;
    } else {
        throw SafeError_Error(unInitilized, {
            namespace: namespace,
            data: data
        });
    }

} module.exports.error = error;

/**
 * Generates a safe throwable error that will be passable.
 * This uses the jckConsole to generate a safe error.
 * The safe error rebuilds the error object so it can be passed as a JSON object
 * and doesn't contain any circular references or other non-serializable objects.
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @returns A safe error object.
 */
function SafeError(namespace, data = null) {
    try {
        // console.info("Generating safe error", {
        //     namespace: namespace,
        //     data: data,
        //     typeof: typeof data
        
        // });

        //if data is not null
        if (data) {
            console.info(`Data from safe error ${namespace}`, {
                data: data
            });
        }

        var e =  error(namespace, data);
        var stack = generateStacktrace(2);
        e = generateSafeError(e);
        e.data = JSON.stringify(data);
        e.stack = stack;

        //reduce the lines of the stracktrace
        // e.stacktrace = () {
            varRemoveFrom = 1;
            varRemoveLines = 2;
            var lzstack = e.stacktrace.split("\n");

            //zero based only remove the total times of varRemoveLines starting at the removeFrom index

            // for (var i = 0; i < lzstack.length; i++) {
            //     if (i >= varRemoveFrom && i < varRemoveFrom + varRemoveLines) {
            //         lzstack.splice(i, 1);
            //     }
            // }

            var i = 0;
            var nStack = [];
            while (i < lzstack.length) {
                if (i >= varRemoveFrom) {
                    if (i <= varRemoveFrom + varRemoveLines) {
                        i++;
                        continue;
                    }
                }
                nStack.push(lzstack[i]);
                i++;
            }

            e.stacktrace = nStack.join("\n");

        // }

        return e;
    } catch (err) {
        console.error("Error generating safe error", {
            err: err,
            namespace: namespace,
            data: data
        })
        return err;
    }
} module.exports.safeError = SafeError;

/**
 * Generates a safe throwable error from a throwable error
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @param {*} keepOrginalStack Whether to keep the orginal stack trace.
 * @returns A safe error object.
 */
function SafeError_Error(e, data = null, keepOrginalStack = false) {
    try {
        // var e = error(namespace, data);
        var stack = {};
        if (keepOrginalStack) {
            stack = parseStackTrace(e.stack);
        } else {
            stack = generateStacktrace(1);
        }
        
        e = JSON.parse(JSON.stringify(e, Object.getOwnPropertyNames(e)));
        e.data = data;
        e.stack = stack;
        return e;
    } catch (err) {
        return err;
    }
} module.exports.SafeError_Error = SafeError_Error;

// /**
//  * Produces a sage error message with the stack trace.
//  * @param {*} error A throwable error.
//  * @returns 
//  */
// function MakeErrorSafe(error) {

//     //if error is not an instance of an error

//     // if (!(error instanceof Error)) {
//     //     throw notAnError;
//     // }
//     var stack = jckConsole.parseStackTrace(error.stack);
//     error = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
//     error.stack = stack;
//     return error;
// } module.exports.makeErrorSage = MakeErrorSafge;


/**
 * Creates a new error registry.
 * @param {*} namespace 
 * @returns The error registry.
 * @property {*} add Adds an error to the registry.
 * @property {*} addErrors Adds an array of errors to the registry.
 * @property {*} safe Returns a safe error object.
 * @property {*} generateSafeError Generates a safe error object.
 * @property {*} SafeError_Error Generates a safe error object from a throwable error.
 * @property {*} errorName When a new error is added to the object, the error id is added to the object, with the error object as the value.
 * @property {*} errorName.throws Throws an error with the given data.
 * @property {*} errorName.safe Returns a safe error object.
 * @property {*} errorName.is Checks if the error is the same as the error with the given id.
 */
function Create(namespace) {
    return {
        namespace: namespace,
        /**
         * Adds an error to the registry.
         * @param {*} id The message id.
         * @param {*} title The title or message of the error.
         * @param {*} description A more detailed description of the error.
         */
        add: function (id, title, description) {

            //if the id is already added to the object
            if (this[id]) {
                throw new Error(`The error with the id ${id} (or a known property of the same name) already exists.`);
            }

            //check to make sure the id is not a known property
            // if (id in this) {

            this[id] = AddError(`${namespace}.${id}`, title, description);
            // this[id].namespace = namespace;
            // this[id].id = id;
            this[id].throw = function (data) {

                //is data object a throwable
                if (data instanceof Error) {
                    data = SafeError_Error(data);
                }

                throw SafeError(`${this.namespace}`, data);
            },
            this[id].safe = function (data) {
                //is data object a throwable
                if (data instanceof Error) {
                    data = SafeError_Error(data);
                }

                return SafeError(`${this.namespace}`, data);
            },
            this[id].is = function (err) {
                if ("namespace" in err) {
                    // console.log("Checking if error is the same", {
                    //     err: err.namespace,
                    //     namespace: this.namespace,
                    //     is: err.namespace == this.namespace
                    // });
                    return err.namespace == this.namespace;
                } 
                return false;
            }

        },
        generateSafeError: generateSafeError,
        /**
         * Returns a safe and sharable error by searching the registry for an error with the given id.
         * @param {*} id The id of the error.
         * @param {*} data The extra data to share to the developer.
         * @returns A safe error object.
         */
        safe: function (id, data) {
            return SafeError(`${namespace}.${id}`, data);
        },
        /**
         * Adds an array of errors to the registry.
         * @param {*} errors 
         * @property {*} []id The message id.
         * @property {*} []title The title or message of the error.
         * @property {*} []description A more detailed description of the error.
         */
        addErrors: function (errors) {
            for (var i = 0; i < errors.length; i++) {
                // console.log("Adding error", errors[i]);
                //check if the id is already added to the object
                // if (!(this[errors[i].id])) {
                if (errorz.search(`${namespace}.${errors[i].id}`)) {
                    //it's loaded already? - update it?
                    var e = errorz.search(`${namespace}.${errors[i].id}`);
                    e.title = errors[i].title;
                    e.description = errors[i].description;

                } else {
                    this.add(errors[i].id, errors[i].title, errors[i].description);
                }
                // }
            }
        }
        // SafeError_Error: SafeError_Error
    }
} module.exports.create = Create;

/**
 * Creates an error registry from a define object.
 * @param {*} define The define object.
 * @returns The error registry.
 * @property {*} add Adds an error to the registry.
 * @property {*} addErrors Adds an array of errors to the registry.
 * @property {*} safe Returns a safe error object.
 * @property {*} generateSafeError Generates a safe error object.
 * @property {*} SafeError_Error Generates a safe error object from a throwable error.
 * @property {*} errorName When a new error is added to the object, the error id is added to the object, with the error object as the value.
 * @property {*} errorName.throws Throws an error with the given data.
 * @property {*} errorName.safe Returns a safe error object.
 * @property {*} errorName.is Checks if the error is the same as the error with the given id.
 */
function CreateFromDefine(define) {

    if (!("namespace" in define)) {
        throw new Error("The define object must have a namespace property in the provided definition.");
    }

    var err = Create(define.namespace);
    if ("errors" in define) {
        for (var key in define.errors) {
            var obj = define.errors[key];
            err.add(key, obj.title, obj.description);
        }
    }
    return err
} module.exports.createFromDefine = CreateFromDefine;

module.exports.tests = [{
    namespace: `${namespace}.defualt`,
    must: true,
    run:   () => {

        var err = Create("test");
        err.add("test", "This is a test thowable.", "Here is the test and it's description.");
        console.error(err.test.message, err.test);

        var s = err.test.safe({
            success: true
        });

        console.error("Safe Error", s);
        return s.data.success;

    }
}];