var namespace = "shopify.api.cart";
var uai = require("../uat/src/interface/interface.js");
var shopifySearch = require("./shopify.search.js");
var sleep = require("../uam/functions/sleep.js").function;

var cartId = null;
var localStorageCartID = "mesho9195Yg6ip";
var localStorageCart = "epso51932Yeto919";


var myFetch = fetch;
// if (uai.server()) {
//   // myFetch = require("node-fetch");
// } else {
//   myFetch = fetch;
// }


var errors = require("./errors.js").create(namespace);
errors.addErrors([{
  id: "unknownError",
  title: "An API Call failed.",
  description: "Unable to work with shopify (api error)."
}, {
  id: "noResults",
  title: "No Results",
  description: "No results were found for your search. Try expanding your search and try again."
}, {
  id: "unauthorized",
  title: "Unauthorized",
  description: "This application does not have permission to access the requested resource. If the problem is due to normal operation, please contact the app's owner or The Universe."
}, {
  id: "forbidden",
  title: "Forbidden",
  description: "You do not have permission to access this resource. It's possible you have been throttled, or your using a VPN that may not have access to this resource. If your using Apple Relay, you can toggle your wi-fi to connect to a new server or disable it and try again."
}, {
  id: "notFound",
  title: "Not Found",
  description: "The requested resource was not found."
}, {
  id: "serverError",
  title: "Server Error",
  description: "The shopify servers encountered an error while processing your request."
}, {
  id: "badRequest",
  title: "Invalid Request",
  description: "The server responded with some errors in your request. Contact support or revise your search and try again."
}, {
  id: "validationFailed",
  title: "Incomplete Options",
  description: "Can't add the product to cart becasue you're missing an option. Please make a selection and try again."
}, {
  id: "noCart",
  title: "No Cart",
  description: "There is no cart to work with. You must add a line item to retrieve a cart."
}, {
  id: "noCartReturned",
  title: "No Cart Returned",
  description: "Our inventory system failed to return a cart object."
}, {
  id: "variantNotAvailable",
  title: "Variant Not Available",
  description: "The variant you selected is not available for sale."
}]);

var infoAPI = require("../.keys/shopify.api.json");

/**
 * Returns the GraphQL endpoint for the Shopify Storefront API.
 * Supports API Version 2021-10.
 * @returns The GraphQL endpoint. https://shopify.dev/docs/storefront-api/reference
 */
function getUri() {
  return `https://${infoAPI.storefront.domain}/api/2021-10/graphql.json`;
} module.exports.getUri = getUri;

/**
 * Updates the cart id in local storage.
 * @param {*} cart The cart object to update the id with.
 */
function updateCartId(cart) {

  if (!(cartId === cart.id) ) {
    cartId = cart.id;
    //store the cart id in local storage or web session (which ever lasts longer)
    localStorage.setItem(localStorageCartID, cartId);
  }
} module.exports.updateCartId = updateCartId;

/**
 * Get's the cart id.
 * Use sync() to ensure the cart is valid.
 * @returns The cart id.
 */
function getCartId() {
  return cartId;
}

/**
 * Global save function to store the cart in local storage.
 * @param {*} items The items to save.
 */
async function save(cart) {
  // // get all items in cart
  // const items = await listCartItems();

  console.log("Saving Cart", cart);
  updateCartId(cart);

  //get all the variant and quanity in an array of items (variantId, quantity)
  const newCart = cart.lines.edges.map(edge => {
    var item = {
      variantId: edge.node.merchandise.id,
      quantity: edge.node.quantity
    };

    console.log("Item", item);

    //if no quantity, set it to 1
    if (!item.quantity) {
      item.quantity = 1;
    }

    return item;

  });


  console.log("New Cart", newCart);

  //store the cart in local storage or web session (which ever lasts longer)
  localStorage.setItem(localStorageCart, JSON.stringify(newCart));

}

/**
 * Resyncs the cart with the Shopify API.
 * If the cartId is null, it will attempt to retrieve it from local storage.
 * If the cartId is not valid, it will recreate the cart.
 * @returns The cart id.
 */
async function sync() {

  try {
    
     //is the cartid null?
    if (!cartId) {
      
      //check is the cart valid
      if (localStorage.getItem(localStorageCartID)) {
        var myCartId = localStorage.getItem(localStorageCartID);
        if (await isCartValid(myCartId)) {
          cartId = myCartId;
          return cartId;
        } else {
          cartId = null;

          var newCart = JSON.parse(localStorage.getItem(localStorageCart));

          if (newCart) {

            console.log("New Cart", newCart);

            //rebuild the cart becasue it's not valid
            for (var i = 0; i < newCart.length; i++) {
              var item = newCart[i];
              console.log("Adding item to cart", item);
              await addVariantToCart(item.variantId, item.quantity);
              await sleep(50);
            }

          }

          return cartId;

        }
      } else {
        return null;
      }

    } else {
      return cartId;
    }

  } catch (error) {
    
    console.error("Error Syncing Cart", error);

  }
  
} module.exports.sync = sync;
 
/**
 * Checks if a cart is still valid by attempting to retrieve it from the Shopify API.
 * @param {String} cartId The ID of the cart to check.
 * @returns {Boolean} Returns true if the cart is valid, false otherwise.
 */
async function isCartValid(cartId) {
  const query = `
      query($cartId: ID!) {
          cart(id: $cartId) {
              ${cartVars}
          }
      }
  `;

  try {
      const cart = SimplifyCart(await RequestFrom({
          query: query,
          variables: { cartId: cartId }
      }));
      return cart != null;  // If the cart is found, response.cart should be non-null
  } catch (error) {
      console.error('Error checking cart validity:', error);
      return false;  // If an error occurs, assume the cart is not valid
  }
} module.exports.isCartValid = isCartValid;

/**
 * Simplifies the response from the Shopify Storefront API.
 * @param {*} response The response from the Shopify Storefront API.
 * @throws noCartReturned If the response does not contain a 'cart'.
 * @returns The 'cart' object from the response.
 */
function SimplifyCart(response) {
  // Helper function to recursively search for 'cart'
  function findCart(obj) {
      if (obj !== null && typeof obj === 'object') {
          if ('cart' in obj) {
              return obj.cart;
          }
          for (const key of Object.keys(obj)) {
              const result = findCart(obj[key]);
              if (result) return result;
          }
      }
      return null;
  }

  // Use the helper function to find the 'cart'
  const cart = findCart(response);
  if (!cart) {
      errors.noCartReturned.throw();  // Throw the custom error if no cart is found
  }

  //adapt cart.checkoutUrl replace https://luxelevels.com with https://luxelevels.myshopify.com
  cart.checkoutUrl = cart.checkoutUrl.replace("https://luxelevels.com", "https://luxelevels.myshopify.com");

  return cart;
} module.exports.SimplifyCart = SimplifyCart;


/**
 * Requests a query
 * @param {*} request The request to send.
 * @property {String} request.query The query to send.
 * @property {Object} request.variables The variables to send.  
 * @returns The response from the query.
 */
async function RequestFrom(request) {

  console.log("Request", request);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': infoAPI.storefront.public
    },
    body: JSON.stringify(request)
  };

  // console.log("Request Options", requestOptions);

  try {

    const response = await myFetch(getUri(), requestOptions);

    try {

      //  console.log("Response", response);

      const jsonResponse = await response.json();
       console.log("JSON Response From Cart", jsonResponse);

      //if json response has an array of errors
      if ("errors" in jsonResponse) {
        throw errors.unknownError.throw(jsonResponse.errors);
      }

      console.log("JSON Response", jsonResponse);

      return jsonResponse;

      //  const edges = jsonResponse.data.products.edges;
      //  allProducts = allProducts.concat(edges.map(edge => edge.node));
      //  hasNextPage = jsonResponse.data.products.pageInfo.hasNextPage;
      //  cursor = jsonResponse.data.products.pageInfo.endCursor;

    } catch (error) {

      // if response is status 200
      if (response.status === 200) {
        //let's check response .size
        if (response.size === 0) {
          throw errors.noResults.throw(error);
        } else {
          throw errors.unknownError.throw(error);
        }
      } else {

        switch (response.status) {
          case 401:
            throw errors.unauthorized.throw();
          case 403:
            throw errors.forbidden.throw();
          case 404:
            throw errors.notFound.throw();
          case 500:
            throw errors.serverError.throw();
          default:
            throw errors.unknownError.throw(error);
        };

      }

    }

  } catch (error) {
    if ("namespace" in error) {
      throw error;
    } else {
      throw errors.unknownError.throw(error);
    }
  }
} module.exports.RequestFrom = RequestFrom;


async function ensureCart() {
  if (!cartId) {

    try {
      await sync();
    } catch (error) {
      
    }

    if (!cartId) {
      throw errors.noCart.throw();
    }

  }
} module.exports.ensureCart = ensureCart;

/**
 * Does the cart exist?
 * @returns The cart id.
 */
function hasCart() {
  var hasCart = false;
  if (cartId !== null) {
    hasCart = true;
  }

  console.log("Has Cart", hasCart);
  return hasCart;

} module.exports.hasCart = hasCart;

/**
  mutation {
  cartCreate(
    input: {
      lines: [
        {
          quantity: 1
          merchandiseId: "gid://shopify/ProductVariant/1"
        }
      ],
      # The information about the buyer that's interacting with the cart.
      buyerIdentity: {
        email: "example@example.com",
        countryCode: CA,
        # An ordered set of delivery addresses associated with the buyer that's interacting with the cart. The rank of the preferences is determined by the order of the addresses in the array. You can use preferences to populate relevant fields in the checkout flow.
        deliveryAddressPreferences: {
          deliveryAddress: {
            address1: "150 Elgin Street",
            address2: "8th Floor",
            city: "Ottawa",
            province: "Ontario",
            country: "CA",
            zip: "K2P 1L4"
          },
        }
      }
      attributes: {
        key: "cart_attribute",
        value: "This is a cart attribute"
      }
    }
  ) {
    cart {
      id
      createdAt
      updatedAt
      lines(first: 10) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
              }
            }
          }
        }
      }
      buyerIdentity {
        deliveryAddressPreferences {
          __typename
        }
      }
      attributes {
        key
        value
      }
      # The estimated total cost of all merchandise that the customer will pay at checkout.
      cost {
        totalAmount {
          amount
          currencyCode
        }
        # The estimated amount, before taxes and discounts, for the customer to pay at checkout.
        subtotalAmount {
          amount
          currencyCode
        }
        # The estimated tax amount for the customer to pay at checkout.
        totalTaxAmount {
          amount
          currencyCode
        }
        # The estimated duty amount for the customer to pay at checkout.
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
}

 */

var cartVars = `id
checkoutUrl
createdAt
updatedAt
lines(first: 250) {
  edges {
    node {
      id
      quantity
      discountAllocations {
        discountedAmount {
          amount
          currencyCode
        }
      }
      cost { 
        amountPerQuantity {
          amount
          currencyCode
        }
        compareAtAmountPerQuantity {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
      merchandise {
        ... on ProductVariant {
          id
          title
          sku
          availableForSale
          priceV2 {
            amount
            currencyCode
          }
          selectedOptions {
            name
            value
          }
          product {
            ${shopifySearch.productVars}
          }
        }
      }
    }
  }
}
buyerIdentity {
  deliveryAddressPreferences {
    __typename
  }
}
attributes {
  key
  value
}
cost {
  totalAmount {
    amount
    currencyCode
  }
  subtotalAmount {
    amount
    currencyCode
  }
  totalTaxAmount {
    amount
    currencyCode
  }
  totalDutyAmount {
    amount
    currencyCode
  }
}`;

/**
 * Create a new cart or use existing one.
 */
async function create(variantId, quantity) {

  const mutation = `
  mutation {
    cartCreate(
      input: {
        lines: [
          {
            quantity: ${quantity}
            merchandiseId: "${variantId}"
          }
        ],
        attributes: {
          key: "from",
          value: "New Site"
        }
      }
    ) {
      cart {
        ${cartVars}
      }
    }
  }
  `;

  const cart = SimplifyCart(await RequestFrom({
    query: mutation
  }));

  // data = SimplifyCart(data);

  // console.log("Data is", data);
  save(cart);

  return cart;

  // cartId = data.cartCreate.cart.id;
} module.exports.create = create;

/**
* Add a product to the cart.
*/
async function addVariantToCart(variantId, quantity) {
  if (!hasCart()) {
    return await create(variantId, quantity);
  } else {
      const mutation = `
          mutation($cartId: ID!, $lines: [CartLineInput!]!) {
              cartLinesAdd(cartId: $cartId, lines: $lines) {
                  cart {
                      ${cartVars}
                  }
              }
          }
      `;
     var cart = SimplifyCart(await RequestFrom({
        query: mutation,
        variables: {
          cartId: cartId,
          lines: [{ quantity: quantity, merchandiseId: variantId }]
        }
      }));

      save(cart);
      return cart;
  }
} module.exports.addVariantToCart = addVariantToCart;

/**
* Remove a product from the cart.
*/
async function removeItem(lineItemId) {
  const mutation = `
      mutation($cartId: ID!, $lineIds: [ID!]!) {
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
              cart {
                  ${cartVars}
              }
          }
      }
  `;
  var cart = SimplifyCart(await RequestFrom({
      query: mutation,
      variables: {
        cartId: cartId,
        lineIds: [lineItemId]
      }
    }));

  save(cart);

  return cart;
} module.exports.removeItem = removeItem;
module.exports.remove = removeItem;

/**
* List all items in the cart.
*/
async function listCartItems() {
  await ensureCart();
  const query = `
      query($cartId: ID!) {
          cart(id: $cartId) {
            ${cartVars}
          }
      }
  `;
  var cart = SimplifyCart(await RequestFrom(
    { 
      query: query,
      variables: { cartId: cartId }
    }));
  
  save(cart);

  return cart;
} module.exports.listCartItems = listCartItems;
module.exports.get = listCartItems;

/**
 * Checkout the cart and return the checkout URL.
 * @returns The checkout URL.
 */
async function checkoutCart() {
  await ensureCart();
  const query = `
      query($cartId: ID!) {
          cart(id: $cartId) {
              ${cartVars}
          }
      }
  `;
  const cart = SimplifyCart(await RequestFrom(
    {
      query: query,
      cartId: cartId
    }));
  return cart.checkoutUrl;
} module.exports.checkoutCart = checkoutCart;
module.exports.checkout = checkoutCart;

/**
 * Update the customer's email associated with the cart.
 * @param {*} email The new email to associate with the cart.
 */
async function updateCustomerEmail(email) {
  await ensureCart();
  const mutation = `
      mutation($cartId: ID!, $email: String!) {
          cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: { email: $email }) {
              cart {
                  ${cartVars}
              }
          }
      }
  `;
  var cart = SimplifyCart(await RequestFrom({
    query: mutation,
    variables: { cartId: cartId, email: email }
  }));

  save(cart);

  return cart;

} module.exports.updateCustomerEmail = updateCustomerEmail;

/**
 * You can add a product to the cart by providing the product id and the options to save.
 * The function will automatically find the variant id and add it to the cart.
 * Example save object
 * {"success":true,"Size":{"success":true,"data":"44","errors":[],"namespace":"ua.select.group"}}
 * @param {*} id The product id to add to the cart.
 * @param {*} save The save object to use.
 */
async function AddProduct(id, save) {

  if (!(save.success)) {
    errors.validationFailed.throw(save);
  }

  var product = await shopifySearch.getProduct(id);

  if (!product) {
    throw errors.notFound.throw();
  }

  //rember products are returned as an array - even if there is only one product
  product = product[0];

  console.log("Attempting an Add to Cart", {
    productId: id,
    product: product,
    save: save
  });

  // Extract the options from the 'save' object
  let matchOptions = {};
  Object.keys(save).forEach(key => {
    if (save[key].success && save[key].data) {
      matchOptions[key] = save[key].data;
    }
  });

  // Find the variant that matches the provided options
  let matchedVariant = product.variants.find(variant =>
    variant.selectedOptions.every(option =>
      matchOptions[option.name] && matchOptions[option.name] === option.value
    )
  );

  if (!matchedVariant) {
    throw errors.noResults.throw("No variant matches the provided options.");
  }

  // Add the matched variant to the cart
  if (matchedVariant.availableForSale) {
    return await addVariantToCart(matchedVariant.id, 1); // Assuming quantity 1 for this example
  } else {
    throw errors.variantNotAvailable.throw(matchedVariant.id);
  }
} module.exports.AddProduct = AddProduct;
module.exports.add = AddProduct;

try {
  window.cart = {
    create: create,
    get: listCartItems,
    add: AddProduct,
    addVariantToCart: addVariantToCart,
    remove: removeItem,
    checkout: checkoutCart,
    updateCustomerEmail: updateCustomerEmail,
    hasCart: hasCart,
    getCartId: getCartId,
    sync: sync
  };

  sync();
} catch (error) {

}



/*** product json example
 * 
{
  "id": "gid://shopify/Product/6887314948273",
  "title": "Slim Fit Rich Grey Linen & Viscose Blazer",
  "handle": "slim-fit-rich-grey-linen-viscose-blazer",
  "description": "Slim Fit Rich Grey Linen & Viscose Blazer",
  "descriptionHtml": "Slim Fit Rich Grey Linen &amp; Viscose Blazer",
  "productType": "Men's Jackets",
  "availableForSale": true,
  "createdAt": "2021-08-17T19:58:27Z",
  "publishedAt": "2024-04-08T16:11:22Z",
  "totalInventory": 4,
  "vendor": "Luxe Levels",
  "tags": [
    "Men's Blazers"
  ],
  "trackingParameters": null,
  "seo": {
    "title": null,
    "description": null
  },
  "priceRange": {
    "minVariantPrice": {
      "amount": "179.0",
      "currencyCode": "USD"
    },
    "maxVariantPrice": {
      "amount": "179.0",
      "currencyCode": "USD"
    }
  },
  "collections": [
    "Men's Clothing",
    "Men's Jackets"
  ],
  "options": [
    {
      "id": "gid://shopify/ProductOption/8840668151985",
      "name": "Size",
      "values": [
        "38",
        "40",
        "42",
        "44",
        "48"
      ]
    }
  ],
  "media": {
    "images": [
      {
        "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/products/IMG_5690.png?v=1629230311",
        "altText": "",
        "position": 0
      },
      {
        "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/products/IMG_5664.png?v=1629230311",
        "altText": "",
        "position": 1
      },
      {
        "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/products/IMG_5665.png?v=1629230311",
        "altText": "",
        "position": 2
      },
      {
        "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/products/IMG_5666.png?v=1629230311",
        "altText": "",
        "position": 3
      }
    ],
    "videos": [],
    "models": [],
    "externalVideos": []
  },
  "variants": [
    {
      "id": "gid://shopify/ProductVariant/40553723265201",
      "title": "38",
      "sku": "LLXG024",
      "availableForSale": true,
      "priceV2": {
        "amount": "179.0",
        "currencyCode": "USD"
      },
      "selectedOptions": [
        {
          "name": "Size",
          "value": "38"
        }
      ]
    },
    {
      "id": "gid://shopify/ProductVariant/40553723297969",
      "title": "40",
      "sku": "LLXG024",
      "availableForSale": false,
      "priceV2": {
        "amount": "179.0",
        "currencyCode": "USD"
      },
      "selectedOptions": [
        {
          "name": "Size",
          "value": "40"
        }
      ]
    },
    {
      "id": "gid://shopify/ProductVariant/40553723363505",
      "title": "42",
      "sku": "LLXG024",
      "availableForSale": true,
      "priceV2": {
        "amount": "179.0",
        "currencyCode": "USD"
      },
      "selectedOptions": [
        {
          "name": "Size",
          "value": "42"
        }
      ]
    },
    {
      "id": "gid://shopify/ProductVariant/40553723429041",
      "title": "44",
      "sku": "LLXG024",
      "availableForSale": true,
      "priceV2": {
        "amount": "179.0",
        "currencyCode": "USD"
      },
      "selectedOptions": [
        {
          "name": "Size",
          "value": "44"
        }
      ]
    },
    {
      "id": "gid://shopify/ProductVariant/40553723461809",
      "title": "48",
      "sku": "LLXG024",
      "availableForSale": true,
      "priceV2": {
        "amount": "179.0",
        "currencyCode": "USD"
      },
      "selectedOptions": [
        {
          "name": "Size",
          "value": "48"
        }
      ]
    }
  ],
  "startingPrice": "$ 179.00",
  "normalPrice": 179,
  "orginalTitle": "Slim Fit Rich Grey Linen & Viscose Blazer LLXG024",
  "optionsByType": {
    "Size": [
      "38",
      "40",
      "42",
      "44",
      "48"
    ]
  }
}
 */